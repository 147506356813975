import React from 'react'
import tw, { css } from 'twin.macro'
import { global } from '../styles/global'
import { HTMLType, LinkType, StyleType } from '../utils/prop-types'
import Button from './button'
import Heading from './heading'
import Text from './text'

const Banner = ({ style, buttonStyle, subtitle, title, description, callToAction }) => {
  return (
    <div
      css={[
        tw`flex flex-col items-start justify-between bg-secondary-100 py-12 px-6 lg:(flex-row p-16 items-center)`,
        style,
      ]}
    >
      <div>
        {subtitle && (
          <Text
            content={subtitle}
            style={[
              global`typography.subtitle`,
              tw`mb-4 font-bold uppercase text-primary-500 text-opacity-47 lg:capitalize`,
            ]}
          />
        )}
        <Heading headingType="h4" content={title} style={tw`font-bold text-primary-500`} />
        {description && <Text content={description} style={tw`mt-4`} />}
      </div>
      <Button
        type="primary"
        size="base"
        theme="cyan-navy"
        label={callToAction.label}
        link={callToAction.link}
        style={css`
          ${tw`mt-8 lg:mt-0`}
        `, buttonStyle}
      />
    </div>
  )
}

Banner.propTypes = {
  style: StyleType,
  buttonStyle: StyleType,
  subtitle: HTMLType,
  title: HTMLType,
  description: HTMLType,
  callToAction: LinkType,
}

export default Banner
